.lifesaver {
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);
}


.effectIncoming {
	transition: all 0.5s ease;
	display:block;
	position:absolute;
	max-width:95vw;
	max-height:95vh;
	border-radius: 6px;
	position:absolute;
	top: 200vh; left:0; right:0; bottom:0;
	margin:auto;
}

.effectActual {
	transition: all 0.5s ease;
	display:block;
	position:absolute;
	max-width:95vw;
	border-radius: 6px;
	max-height:95vh;
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	margin:auto;
}

.effectOutgoing {
	transition: all 0.5s ease;
	display:block;
	position:absolute;
	max-width:95vw;
	opacity: 0.1;
	border-radius: 6px;
	max-height:95vh;
	position:absolute;
	top:-300vh; left:0; right:0; bottom:0;
	margin:auto;
}

.animation {
	z-index: 20;
	transition: all 0.3s ease;
}

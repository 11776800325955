// Header Base

.disconnected {
  z-index: 1000;
	background-color: rgba(0,0,0,0.8);
	color: white;
	width:100%;
	padding-top:10vh;
	text-align:center;
	position:fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
  transition: all .2s;
}
